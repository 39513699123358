import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link, navigate } from 'gatsby';
import { Query, useQuery, useMutation } from 'react-apollo';

import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import moment from 'moment';
import * as Yup from 'yup';
// const { Formik, Field } = Loadable(() => import('formik')); // code-splitted, on demand loaded component
import { XCircle, CheckCircle, ExternalLink } from 'react-feather';
import { Formik, Field } from 'formik';
import Modal from '../../components/Modal';
import WithLocation from '../../components/WithLocation';
import SEO from '../../components/seo';
import EventTracker from '../../components/EventTracker';
import PropertyReviews from '../../components/PropertyReviews';

import Layout from '../../layouts/DefaultLayout';
import CMSContent from '../../components/CMSContent';
import PropertyDetailsList from '../../components/PropertyDetailsList';
import Loading, { Dots } from '../../components/Loading';
import { client } from '../../util/apollo/client';
import PaypalLogo from '../../images/paypal-logo.png';
import PaymentsAccepted from '../../images/payments-accepted-v2.png';
import GuestCheckoutGuide from '../../images/paypal-complete-transaction-as-guest-v4.jpg';
import PropertyQuoteProvider, {
  PropertyQuoteConsumer,
  PropertyQuoteContext,
} from '../../contexts/PropertyQuote';
import PseudoSelect from '../../components/PseudoSelect';
import GuestCounterForm from '../../components/RequestInfoForm/components/GuestCounterForm';
import LazyImage from '../../components/LazyImage';
import styles from './styles.module.scss';
import { getSiteMeta } from '../../util/providers';
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';

const validationSchema = Yup.object().shape({
  emailAddress: Yup.string().email().required('Please provide a valid email address.'),
  // numberOfAdults: Yup.number()
  //   .integer()
  //   .min(1, 'Please tell us about your group.')
  //   .required('Please tell us about your group.'),
});

const PaypalButtonSwap = ({ values, errors, promo, discount, search, handleSubmit, setFormIsValid, sandbox }) => {
  return values.emailAddress != '' && !errors.emailAddress ? (
    <>

      <PropertyQuoteConsumer>
        {context => {
          return <PayPalScriptProvider
            options={{
              clientId:
                sandbox == 'true' ? 'AQykNEwVR5Ugd7H3UHBv0lvT6HrENTa0xL5UIR60i__f3Y70QoPXt1a6MMiqiBtmZO0-IOP0cYzTJg0Q' :
                  'AUtw8PMfTIs_WdSHa-9fyf0xYpk4xpm-_3ZTfk2Vg2ZxQTurrCa7wJEbwDSTNRm-zzbbdz3tJjTnNz_Z',
              //
              components: 'buttons',
              currency: 'USD',
            }}
          >
            <div
              style={{
                position: 'absolute',
                zIndex: '-1',
                height: '40px',
              }}
            >
              <Dots />
            </div>
            <ButtonWrapper
              promo={promo}
              discount={discount}
              showSpinner={false}
              values={values}
              context={context}
              errors={errors}
              search={search}
            />
          </PayPalScriptProvider>
        }}
      </PropertyQuoteConsumer>
    </>
  ) : (
    <><button
      className="btn btn-secondary text-primary w-100  font-weight-bold"
      onClick={() => {
        const reserveFormEl = document.getElementById(
          'reserveForm'
        );
        //  alert(reserveFormEl.checkValidity());
        handleSubmit();

        setFormIsValid(reserveFormEl.checkValidity());
        document
          .getElementById('top-paragraph')
          .scrollIntoView({
            behavior: 'smooth',
          });


      }}
      type="submit"
    >
      Continue to Payment
    </button>

    </>
  )

}
const Reviews = () => {
  const [width, setWidth] = useState(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);


  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://static.elfsight.com/platform/platform.js';
    scriptTag.addEventListener('load', () => setScriptLoaded(true));
    document.body.appendChild(scriptTag);
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => { });
    };
  }, []);

  return <>


    <div style={{
      display: scriptLoaded && width <= 768 ? 'none' : 'block'
    }} className="elfsight-app-05647332-4adf-4fa8-ba0c-7a06dcdb642e" data-elfsight-app-lazy></div>
    <div style={{
      display: scriptLoaded && width > 768 ? 'none' : 'block'
    }}
      className="elfsight-app-f4564248-fbb7-4d47-a41b-a46c4b05f657" data-elfsight-app-lazy></div>
  </>
  // return width > 768 ? <>
  //   <script src={`https://static.elfsight.com/platform/platform.js?${width}`} data-use-service-core defer></script>


  // </> : <>
  //   <script src={`https://static.elfsight.com/platform/platform.js?${width}`} data-use-service-core defer></script>

  // </>
}

const QUOTE_DATA = gql`
  query PropertyQuote(
    $propertyId: Int
    $checkIn: Date
    $checkOut: Date
    $numberOfPets: Int
    $promoCode: String
    $discountCode: String
  ) {
    propertyQuote(
      where: {
        propertyId: $propertyId
        checkIn: $checkIn
        checkOut: $checkOut
        numberOfPets: $numberOfPets
        promoCode: $promoCode
        discountCode: $discountCode
      }
    ) {
      baseRate
      rate
      cleaningFee
      petFee
      administrativeFee
     
      damageDeposit
      avgPerNight
      totalNights
      fees
      tax {
        lodgingTax
        countyTax
        stateTax
      }
      paymentRequests {
        description
        date
        amount
      }
      prepaidDiscount {
        percent
        baseRateDiscount
        baseRateDiscountApplied
        rate
        tax {
          lodgingTax
          countyTax
          stateTax
        }
      }
      promoDiscount {
        type
        code
        percent
        baseRateDiscount
        baseRateDiscountApplied
        rate
        tax {
          lodgingTax
          countyTax
          stateTax
        }
      }      
      property {
        id
        name
        sleeps
        bedrooms
        bathrooms
        stateTaxRate
        countyTaxRate
        petFee
      }
    }
  }
`;

const CREATE_PAYPAL_ORDER = gql`
  mutation CreatePaypalOrder(
    $checkIn: String!
    $checkOut: String!
    $rateToValidate: Float!
    $prepaidDiscountRequested: Boolean
    $promoCode: String
    $discountCode: String
    $propertyId: Int!
    $address: String
    $city: String
    $state: String
    $postalCode: String
    $country: String
    $firstName: String
    $lastName: String
    $emailAddress: String!
    $phoneNumber: String
    $additionalDetails: String
    $numberOfAdults: Int
    $numberOfChildren: Int
    $numberOfPets: Int
    $numberOfVehicles: Int
    $numberOfVisitors: Int
    $utmParams: JSON
  ) {
    createPaypalOrder(
      data: {
        checkIn: $checkIn
        checkOut: $checkOut
        rateToValidate: $rateToValidate
        promoCode: $promoCode
        discountCode: $discountCode
        propertyId: $propertyId
        address: $address
        city: $city
        state: $state
        postalCode: $postalCode
        country: $country
        firstName: $firstName
        lastName: $lastName
        emailAddress: $emailAddress
        phoneNumber: $phoneNumber
        additionalDetails: $additionalDetails
        numberOfAdults: $numberOfAdults
        numberOfChildren: $numberOfChildren
        numberOfPets: $numberOfPets
        numberOfVehicles: $numberOfVehicles
        numberOfVisitors: $numberOfVisitors
        prepaidDiscountRequested: $prepaidDiscountRequested
        utmParams: $utmParams
      }
    ) {
      data
    }
  }
`;


const getFinalPrice = ({ propertyQuote, prepaidDiscountRequested }) => {
  if (prepaidDiscountRequested && propertyQuote?.prepaidDiscount?.rate) {
    return propertyQuote?.prepaidDiscount.rate;
  }
  return propertyQuote?.paymentRequests[0].amount;
}

// const getFinalPriceForValidation = ({ promo, propertyQuote, prepaidDiscountRequested }) => {
//   if (prepaidDiscountRequested && propertyQuote?.prepaidDiscount?.rate) {
//     return propertyQuote?.prepaidDiscount.rate;
//   }
//   return propertyQuote?.paymentRequests[0].amount; 
// }

const ButtonWrapper = ({ showSpinner, promo, discount, values, context, errors, search }) => {
  const [{ isPending }] = usePayPalScriptReducer();
  // const [loading, setLoading] = useState(false)
  console.log('search2', search)
  console.log('discount4', discount)
  // console.log('errors', errors);
  let invoiceId = null;
  // console.log('client', client);
  const [paypalMessagingModalIsOpen, setPaypalMessagingModalIsOpen] = useState(
    false
  );
  const [
    paypalErrorMessagingModalIsOpen,
    setPaypalErrorMessagingModalIsOpen,
  ] = useState(false);

  const propertyQuoteContext = useContext(PropertyQuoteContext);

  const createPaypalOrder = async ({ variables }) =>
    client.mutate({
      mutation: CREATE_PAYPAL_ORDER,
      variables: variables,
    });

  function createOrder({ values, rateToValidate }) {
    console.log('context2', context)
    const prepaidDiscount = context.state.prepaidDiscount == 'YES';
    const utmparamsRaw = Cookies.get('utmparams');
    // console.log('decoded', JSON.parse(decoded))
    const utmParams = utmparamsRaw ? JSON.stringify(JSON.parse(utmparamsRaw)) : null;
    return (
      createPaypalOrder({
        variables: {
          ...values,
          utmParams,
          //country: 'US',
          rateToValidate,
          prepaidDiscountRequested: prepaidDiscount,
          promoCode: promo,
          discountCode: discount
        },
      })
        // .then(response => response.json())
        .then(response => {
          // Your code here after create the order
          console.log('response', response);
          console.log(
            ' response.data.createPaypalOrder.data.id',
            response.data.createPaypalOrder.data.id
          );
          return response.data.createPaypalOrder.data.id;
        }).catch(err => {
          console.log('err', err)
          // if (err?.graphQLErrors) {
          //   console.log('err2', err?.graphQLErrors)
          //   // context.setErrors(err?.graphQLErrors);
          // }
          alert(err.message.replace(/graphql error:/gi, ''))
        })
    );
  }
  function onApprove(data, trackEvent) {
    // replace this url with your server
    console.log('onApprove:data', data);
    return fetch(`${process.env.BHIP_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // use the "body" param to optionally pass additional order information
      // like product ids and quantities
      body: JSON.stringify({
        query: `{
          getPaypalOrderDetails(where: {orderId: "${data.orderID}"}) {
            data
          }
        }`,
      }),
      // body: JSON.stringify({
      //     cart: [
      //         {
      //             sku: "1blwyeo8",
      //             quantity: 2,data.orderId
      //         },
      //     ],
      // }),
    })
      .then(response => response.json())
      .then(orderData => {
        // Your code here after capture the order
        console.log('onApprove:orderdata', orderData);
        console.log('onApprove:propertyQuoteContext', propertyQuoteContext);
        console.log('onApprove:orderData.data.getPaypalOrderDetails.data.status', orderData.data.getPaypalOrderDetails.data.status);
        if (orderData.data.getPaypalOrderDetails.data.status == 'COMPLETED') {
          console.log('onApprove:window', typeof window);
          //setPaypalMessagingModalIsOpen(true);
          if (typeof window != 'undefined') {

            // window.dataLayer = window.dataLayer || [];
            // function gtag() { dataLayer.push(arguments); }

            // console.log('onApprove:gtag');
            // gtag('js', new Date());
            // gtag('config', 'G-9P4L5V43YE');
            try {
              console.log('onApprove:try');
              const propertyQuote = propertyQuoteContext.state.propertyQuote;

              trackEvent('purchase', {
                transactionID: `PP-${data.orderID}`,
                value: propertyQuote.promoDiscount ? propertyQuote.promoDiscount.rate : propertyQuote.rate,  // Updated value: $2000 (after discount) + $482 (taxes) + $698 (fees)
                currency: "USD",
                tax: propertyQuote.promoDiscount ? propertyQuote.promoDiscount.tax.lodgingTax : propertyQuote.tax.lodgingTax,  // Added taxes
                coupon: propertyQuote.promoDiscount ? propertyQuote.promoDiscount.code : propertyQuote.promoDiscount,
                fees: propertyQuote.promoDiscount ? propertyQuote.promoDiscount.fees : propertyQuote.fees,  // Custom parameter for fees
                items: [{
                  item_id: propertyQuote.property.id,
                  item_name: propertyQuote.property.name,
                  item_category: "Vacation Rental",
                  price: propertyQuote.rate,  // Original price before discount
                  discount: propertyQuote.promoDiscount ? propertyQuote.promoDiscount.baseRateDiscountApplied : 0,
                  quantity: 1
                }]
              });
              console.log('onApprove:gtag:purchase');
            } catch (err) {
              console.log('gtagError', err)
            }
            navigate('/rentals', {
              state: {
                paypalSuccess: {
                  propertyQuote: propertyQuoteContext.state.propertyQuote,
                  checkIn: search.checkin,
                  checkOut: search.checkout,
                }
              }
            });
          }




        } else {
          setPaypalErrorMessagingModalIsOpen(true);
        }
      })
      .catch(() => {
        setPaypalErrorMessagingModalIsOpen(true);
      });
  }

  return (
    <>

      {((showSpinner && isPending)) && <div className="spinner" />}

      <Modal
        // expanded={true}
        disableFocusTrap={true}
        isOpen={paypalErrorMessagingModalIsOpen}
        customHeader={() => {
          return (
            <>
              <h5 className="modal-title">
                <XCircle color="red" className="mr-1" />

                We're sorry ...
              </h5>
            </>
          );
        }}
        onClose={() => setPaypalErrorMessagingModalIsOpen(false)}
      >
        <>
          <p className="mb-2">
            Something went wrong. Rest assured, no charges have been made to
            your account. Please email us to help with your reservation.
          </p>
          <a
            href="mailto:BeachHousesinParadise@gmail.com"
            className="btn btn-secondary mt-2 text-primary font-weight-bold w-100"
          >
            <span className="font-weight-bold">Email Support</span>
          </a>
        </>
      </Modal>
      <Modal
        // expanded={true}
        disableFocusTrap={true}
        isOpen={paypalMessagingModalIsOpen}
        title={'Property Reservation'}
        customHeader={() => {
          return (
            <>
              <h5 className="modal-title">
                <CheckCircle color="green" className="mr-1" />
                Thank you for your payment!
              </h5>
            </>
          );
        }}
        onClose={() => setPaypalMessagingModalIsOpen(false)}
      >
        <>
          <p className="mb-2">
            Your dates are now reserved. We will email you shortly to confirm
            your details and finalize your reservation.
          </p>
          <div className="mb-1plus">
            {propertyQuoteContext.state.propertyQuote?.property?.name}
          </div>
          <div className="mb-1plus">
            <span>
              {moment(search.checkin)
                .format('dddd')
                .substring(0, 3) +
                ', ' +
                moment(search.checkin).format('MMM DD, YYYY')}{' '}
            </span>{' '}
            -{' '}
            <span>
              {moment(search.checkout)
                .format('dddd')
                .substring(0, 3) +
                ', ' +
                moment(search.checkout).format('MMM DD, YYYY')}{' '}
            </span>
          </div>
          <button
            className="btn btn-secondary mt-2 text-primary font-weight-bold w-100"
            onClick={() => {
              setPaypalMessagingModalIsOpen(false);
            }}
          >
            OK
          </button>

          {/* 
Thank you!

 
Bella Vista
Mon, Nov 13, 2023 - Sat, Nov 18, 2023
 
Button text: [OK]

Error modal:

We're sorry ...
Something went wrong. Rest assured, no charges have been made to your account. Please email us for help with your reservation.
 
Button Text: [Email Support]

Site messaging (if we recalculate for availability):
The dates you selected are no longer available. */}
        </>
      </Modal>
      <EventTracker>
        {({ trackEvent }) => {
          return (
            <PayPalButtons
              style={{ layout: 'vertical' }}
              disabled={false}
              forceReRender={[{ layout: 'vertical' }]}
              fundingSource={'paypal'}

              // onClick={(data, actions) => {
              //   console.log('values', values);
              //   console.log('data', data);
              //   console.log('actions', actions);
              //   return actions.reject(); // TODO: Validate
              //   // return actions.resolve();
              //   // return validateAndInvoice({
              //   //   values,
              //   //   rateToValidate: propertyQuoteContext.state.propertyQuote?.rate,
              //   // }).then(() => {
              //   //   console.log('invoiceId', invoiceId);
              //   //   if (invoiceId) {
              //   //     console.log('RESOLVED');
              //   //     return actions.resolve();
              //   //   }

              //   //   return actions.reject();
              //   // });
              // }}
              createOrder={args => {

                console.log('args', args);
                const rateToValidate = getFinalPrice({ propertyQuote: propertyQuoteContext.state.propertyQuote, prepaidDiscountRequested: propertyQuoteContext.state.prepaidDiscount == 'YES' });
                console.log('rateToValidate', rateToValidate)
                return createOrder({
                  values,
                  rateToValidate,
                });
              }}
              onApprove={(data) => {
                return onApprove(data, trackEvent)
              }}

            />
          )
        }}</EventTracker>
      {/* <PayPalButtons
        style={{ layout: 'vertical' }}
        disabled={false}
        forceReRender={[{ layout: 'vertical' }]}
        fundingSource={'card'}
        createOrder={async args => {
          //  setLoading(true);
          console.log('args2', args);
          const rateToValidate = getFinalPrice({ propertyQuote: propertyQuoteContext.state.propertyQuote, prepaidDiscountRequested: propertyQuoteContext.state.prepaidDiscount == 'YES' });
          console.log('rateToValidate2', rateToValidate)
          // const result = await createOrder({
          //   values,
          //   rateToValidate,
          // });

          return createOrder({
            values,
            rateToValidate,
          });
        }}
        onApprove={onApprove}
      /> */}
    </>
  );
};

const QuotePanel = ({
  search,
  values,
  errors,
  location,
  numberOfPets,
  guestNumbersLabel,
  propertyId,
  property,
  promo,
  discount,
  prepaidDiscountRequested,
  priceDetailModalIsOpen,
  onPriceDetailClick,
  setFormIsValid,
  sandbox,
  handleSubmit
}) => {

  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);

  //console.log('numberOfPets2', numberOfPets);
  const propertyQuoteContext = useContext(PropertyQuoteContext);

  const { data: quoteData, error, loading, refetch } = useQuery(QUOTE_DATA, {
    // skip: numberOfPets == null,
    variables: {
      propertyId: propertyId,
      numberOfPets: numberOfPets,
      checkIn: search.checkin,
      checkOut: search.checkout,
      promoCode: promo,
      discountCode: discount
    },
  });

  useEffect(() => {
    if (error?.graphQLErrors) {
      //  console.log('error', error.message);
      // propertyQuoteContext.setErrors(error?.graphQLErrors);
      setErrorModalIsOpen(true);
    }
  }, [error?.graphQLErrors]);

  useEffect(() => {
    if (!loading) {
      $('[data-toggle="popover"]').popover({
        container: 'body',
      });
    }
  }, [loading]);

  //console.log('location', location);
  if (loading) {
    return (
      <div className=" mt-3">
        <Loading />
      </div>
    );
  }
  if (error?.graphQLErrors) {
    return (
      <Modal
        // expanded={true}
        disableFocusTrap={true}
        isOpen={errorModalIsOpen}
        title={'Quote Unavailable'}
        customHeader={() => {
          return (
            <>
              <h5 className="modal-title">
                <XCircle color="red" className="mr-1" />
                Quote Unavailable
              </h5>
            </>
          );
        }}
        onClose={() => setErrorModalIsOpen(false)}
      >
        <>
          <p className="mb-2">
            The dates you picked aren't bookable (they might be reserved, in the past, or don't meet our length-of-stay requirements), but we've got plenty of other great options.
          </p>

          <button
            className="btn btn-secondary mt-2 text-primary font-weight-bold w-100"
            onClick={() => {
              //              setErrorModalIsOpen(false);
              window.location.href =
                window.location.pathname.replace('/reserve', '') +
                '?reserve=true';
            }}
          >
            Go Back
          </button>
        </>
      </Modal>
    );
  }
  // console.log('error', error);
  // console.log('error?graphQLErrors', error?.graphQLErrors);
  // if (Array.isArray(errors.graphQLErrors)) {
  //   if (errors.graphQLErrors.length > 0) {
  //     return errors.graphQLErrors[0].message;
  //   }
  // }

  const { propertyQuote } = quoteData;
  propertyQuoteContext.setPropertyQuote(propertyQuote);
  console.log('property', property);
  let discountNode = propertyQuote.promoDiscount
  if (prepaidDiscountRequested == 'YES' && propertyQuote.prepaidDiscount) {
    discountNode = propertyQuote.prepaidDiscount
  }
  //console.log('propertyQuote2', propertyQuote)
  // const { property } = propertyQuote;
  return (
    <>
      <div >
        <h2 className="mb-2">Rate Details</h2>
        <div className="d-flex">
          <div><img
            alt={`${property.name} vacation rental property`}
            width="166"
            className="no-resize"
            src={
              property.sitePage.featuredImageCollection.files[0].medium.fullUrl
            }
          />
          </div>
          <div className="pl-2">
            <h2 >{property.name}</h2>
            <div className={`mt-1plus`}>
              <PropertyDetailsList hideLabels={true} property={property} wrap={true} />
            </div>
            <div className="mt-1plus">
              {
                property.amenities.map(amenity => {
                  return <span class="badge badge-pill badge-light mr-1">{amenity.name}</span>
                })

              }
            </div>
          </div>
        </div>
      </div>

      <div className="form-group d-none">
        <div className="border-bottom mt-2 mb-2 pb-2">
          <div className="d-flex  flex-wrap  align-items-middle justify-content-between">
            <h2 className="m-0">{property.name}</h2>
            <span>
              <img src="/icon-clean.svg" className="mr-1" />
              Spotlessly Clean
            </span>
          </div>
          <div className={`mt-2 ${styles.propertyDetailParent}`}>
            <PropertyDetailsList property={property} wrap={true} />
          </div>
        </div>
      </div>

      <div className="form-group d-none">
        <div className="border-bottom mt-2 mb-2 pb-2">
          <div className="d-flex  flex-wrap justify-content-between mb-1plus">
            <b>Check-in</b>
            <span>
              {moment(search.checkin)
                .format('dddd')
                .substring(0, 3) +
                ', ' +
                moment(search.checkin).format('MMM DD, YYYY')}{' '}
              (at 5pm)
            </span>
          </div>
          <div className="d-flex  flex-wrap justify-content-between">
            <b>Check-out</b>
            <span>
              {moment(search.checkout)
                .format('dddd')
                .substring(0, 3) +
                ', ' +
                moment(search.checkout).format('MMM DD, YYYY')}{' '}
              (at 11am)
            </span>
          </div>
        </div>
      </div>

      {/* <div className="form-group">
        <div className="border-bottom mt-2 mb-3 pb-2">
          <div className="d-flex justify-content-between mb-1plus">
            <b>Guest info</b>
            <span>{guestNumbersLabel || 'Required'}</span>
          </div>
        </div>
      </div> */}
      <div className="form-group">

        <div className="border-top border-bottom mt-2 mb-2 pt-2 pb-2">
          <div className="d-flex justify-content-between mb-1plus">
            Owner's Rate ({propertyQuote.totalNights} x nights)
            <span style={{ 'textDecoration': propertyQuote.promoDiscount ? 'line-through' : '' }}>{moneyFormatter.format(propertyQuote.baseRate)}</span>
          </div>




          {propertyQuote.promoDiscount &&
            <div className="d-flex justify-content-between mb-1plus text-success  font-weight-bold">
              <span>Promotional Rate ({propertyQuote.promoDiscount.percent}% off) {propertyQuote.totalNights > 30 && <><a
                style={{ cursor: 'pointer' }}
                tabIndex="0"
                id="more-info"
                className="ml-1plus popover-dismiss"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-html="true"
                data-template='<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
                data-content={`Includes <b>${propertyQuote.promoDiscount.percent}%</b> discount on the owner's rate for the first 30 nights of your stay.`}
              >
                <img src="/more-info.svg" width="22" height="22" />
              </a></>}</span>
              <span>{moneyFormatter.format(propertyQuote.promoDiscount.baseRateDiscountApplied)}</span>
            </div>}
          {prepaidDiscountRequested == 'YES' && propertyQuote.prepaidDiscount && <div className="d-flex justify-content-between _mb-1plus  text-success font-weight-bold">
            <span>Full Payment Discount</span>
            <span >-{moneyFormatter.format(
              (propertyQuote.promoDiscount ? propertyQuote.promoDiscount.baseRateDiscountApplied : propertyQuote.baseRate) / propertyQuote.prepaidDiscount.percent
            )}</span>
          </div>}
          {/* <div className="d-flex justify-content-between mb-1plus">
            <span>Fees</span>
            <span>{moneyFormatter.format(propertyQuote.fees)}</span>
          </div>
          <div className="d-flex justify-content-between mb-1plus">
            <span>Taxes</span>
            {discountNode ?
              <span>{moneyFormatter.format(discountNode.tax?.lodgingTax)}</span> :
              <span>{moneyFormatter.format(propertyQuote.tax.lodgingTax)}</span>}
          </div> */}
          {/* {numberOfPets > 0 && (
            <div className="border-bottom d-flex justify-content-between pb-2 mb-2">
              <b>Pet Fee</b>
              <span>{moneyFormatter.format(propertyQuote.petFee)}</span>
            </div>
          )}
          <div className="border-bottom d-flex justify-content-between pb-2 mb-2">
            <b>Total</b>
            <span>{moneyFormatter.format(propertyQuote.rate)}</span>
          </div> */}
          {/* <div className="text-right">
            <button
              className="btn btn-link p-0 outline-none"
              style={{ outline: 'none' }}
              onClick={() => {
                onPriceDetailClick(true)
              }}
              href="#"
            >
              Price Details
            </button>
          </div> */}
        </div>

        <div>

          <PropertyQuoteConsumer>
            {context => {

              return context.state.propertyQuote && (
                <div className="form-group _d-none">

                  {
                    !context.state.propertyQuote.prepaidDiscount && context.state.propertyQuote?.paymentRequests.length == 1 && <>
                      <div >

                        <div>

                          <div >

                            {context.state.propertyQuote?.promoDiscount ? <><table className="my-2 w-100">

                              <tr>
                                <td className="total1 font-weight-bold">Total</td>
                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                  context.state.propertyQuote?.promoDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.promoDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                )}</td>
                              </tr>

                              <tr>
                                <td colSpan={2}>
                                  <button type="button" onClick={() => {
                                    onPriceDetailClick(true)
                                  }} className="btn btn-link text-primary p-0 d-flex items-center"><u style={{ marginRight: '.4rem' }}>Click here</u> for full rate details <ExternalLink width={14} className=" ml-1" /></button>
                                </td>
                              </tr>
                            </table>

                              <div className="border p-2 d-none ">
                                <div className="mb-2"><b id="payment-1">Your payment is secure</b></div>

                                <div class="text-center">
                                  <div className="mt-3 mb-2">
                                    <img src={PaymentsAccepted} alt="Payments Accepted" />
                                  </div>
                                  <PaypalButtonSwap values={values} errors={errors} promo={promo} discount={discount} search={search} sandbox={sandbox} handleSubmit={handleSubmit} setFormIsValid={setFormIsValid} />


                                  <div className="mt-2">
                                    <b>{moneyFormatter.format(
                                      context.state.propertyQuote?.promoDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.promoDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                    )}</b>
                                    {' '} is the pending transaction amount.
                                  </div>
                                  <div className="text-muted text-small">
                                    You won't be charged yet
                                  </div>
                                </div>
                                <div className="text-center  col col-md-6 pt-2 small pl-0">
                                  <i>A PayPal account is not required.</i> <button type="button" onClick={() => setGuestCheckoutModalIsOpen(true)} className="btn btn-hyperlink  text-primary p-0 align-baseline small"><i className="small">Click for more info</i></button>.
                                </div>
                              </div>

                            </> : <><table className="my-2 w-100">


                              <tr>
                                <td className="total2 font-weight-bold">Total</td>
                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                  context.state.propertyQuote?.baseRate + context.state.propertyQuote?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                )}</td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <button type="button" onClick={() => {
                                    onPriceDetailClick(true)
                                  }} className="btn btn-link text-primary p-0 d-flex items-center"><u style={{ marginRight: '.4rem' }}>Click here</u> for full rate details <ExternalLink width={14} className=" ml-1" /></button>
                                </td>
                              </tr>

                            </table>
                              <div className="border p-2 d-none ">
                                <div className="mb-2"><b id="payment-2">Your payment is secure</b></div>

                                <div class="text-center">
                                  <div className="mt-3 mb-2">
                                    <img src={PaymentsAccepted} alt="Payments Accepted" />
                                  </div>
                                  <PaypalButtonSwap values={values} errors={errors} promo={promo} discount={discount} search={search} sandbox={sandbox} handleSubmit={handleSubmit} setFormIsValid={setFormIsValid} />

                                  <div className="mt-2">
                                    <b>{moneyFormatter.format(
                                      context.state.propertyQuote?.paymentRequests[0].amount
                                    )}</b>
                                    {' '} is the pending transaction amount.
                                  </div>

                                  <div className="text-muted text-small">
                                    You won't be charged yet
                                  </div>
                                </div>
                                <div className="text-center  col col-md-6 pt-2 small pl-0">
                                  <i>A PayPal account is not required.</i> <button type="button" onClick={() => setGuestCheckoutModalIsOpen(true)} className="btn btn-hyperlink  text-primary p-0 align-baseline small"><i className="small">Click for more info</i></button>.
                                </div>
                              </div>
                            </>}
                          </div>
                        </div>
                      </div>
                    </>}

                  {
                    context.state.propertyQuote.prepaidDiscount && context.state.propertyQuote?.paymentRequests.length > 1 && <>

                      {context.state.prepaidDiscount == 'YES' && context.state.propertyQuote?.paymentRequests.length > 1 && <div >

                        <table className="my-2 w-100">


                          {context.state.propertyQuote?.promoDiscount ?
                            <>



                              <tr>
                                <td className="total3 font-weight-bold">Total</td>
                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                  context.state.propertyQuote?.prepaidDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.prepaidDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                )}</td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <button type="button" onClick={() => {
                                    onPriceDetailClick(true)
                                  }} className="btn btn-link text-primary p-0 d-flex items-center"><u style={{ marginRight: '.4rem' }}>Click here</u> for full rate details <ExternalLink width={14} className=" ml-1" /></button>
                                </td>
                              </tr>




                            </> :
                            <>

                              <tr>
                                <td className="total4 font-weight-bold">Total</td>
                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                  context.state.propertyQuote?.prepaidDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.prepaidDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                )}</td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <button type="button" onClick={() => {
                                    onPriceDetailClick(true)
                                  }} className="btn btn-link text-primary p-0 d-flex items-center"><u style={{ marginRight: '.4rem' }}>Click here</u> for full rate details <ExternalLink width={14} className=" ml-1" /></button>
                                </td>
                              </tr>
                            </>}


                        </table>

                        <div className="border p-2 d-none ">
                          <div className="mb-2"><b id="payment-3">Your payment is secure</b></div>

                          <div className="text-center">
                            <div className="mt-3 mb-2">
                              <img src={PaymentsAccepted} alt="Payments Accepted" />
                            </div>
                            <PaypalButtonSwap values={values} errors={errors} promo={promo} discount={discount} search={search} sandbox={sandbox} handleSubmit={handleSubmit} setFormIsValid={setFormIsValid} />

                            <div className="mt-2">

                              <b>{context.state.propertyQuote?.promoDiscount ?
                                <>{moneyFormatter.format(
                                  context.state.propertyQuote?.prepaidDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.prepaidDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                )} </> : <>
                                  {moneyFormatter.format(
                                    context.state.propertyQuote?.prepaidDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.prepaidDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                  )}
                                </>}</b>
                              {' '} is the pending transaction amount.
                            </div>

                            <div className="text-muted text-small">
                              You won't be charged yet
                            </div>
                          </div>
                          <div className="text-center  col col-md-6 pt-2 small pl-0">
                            <i>A PayPal account is not required.</i> <button type="button" onClick={() => setGuestCheckoutModalIsOpen(true)} className="btn btn-hyperlink  text-primary p-0 align-baseline small"><i className="small">Click for more info</i></button>.
                          </div>

                        </div>

                      </div>}
                      {context.state.prepaidDiscount == 'NO' && context.state.propertyQuote?.paymentRequests.length > 1 && <div >

                        <table className="my-2 w-100">



                          {context.state.propertyQuote?.promoDiscount ?
                            <>


                              <tr>
                                <td className="total5 font-weight-bold">Total</td>
                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                  context.state.propertyQuote?.promoDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.promoDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                )}</td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <button type="button" onClick={() => {
                                    onPriceDetailClick(true)
                                  }} className="btn btn-link text-primary p-0 d-flex items-center"><u style={{ marginRight: '.4rem' }}>Click here</u> for full rate details <ExternalLink width={14} className=" ml-1" /></button>
                                </td>
                              </tr>

                            </> : <>

                              <tr>
                                <td className="total6 font-weight-bold">Total</td>
                                <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                  context.state.propertyQuote?.baseRate + context.state.propertyQuote?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                )}</td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <button type="button" onClick={() => {
                                    onPriceDetailClick(true)
                                  }} className="btn btn-link text-primary p-0 d-flex items-center"><u style={{ marginRight: '.4rem' }}>Click here</u> for full rate details <ExternalLink width={14} className=" ml-1" /></button>
                                </td>
                              </tr>
                            </>}

                          <tr className="text-muted">
                            <td>Due now (50%)</td>
                            <td className="  pl-3 text-right">{moneyFormatter.format(
                              context.state.propertyQuote?.paymentRequests[0].amount
                            )}</td>
                          </tr>
                          <tr className="text-muted">
                            <td>Due {
                              moment(context.state.propertyQuote?.paymentRequests[1].date)
                                .format('dddd')
                                .substring(0, 3) +
                              ', ' +
                              moment(context.state.propertyQuote?.paymentRequests[1].date).format('MMM DD, YYYY')
                            } (50%)</td>
                            <td className="  pl-3 text-right">{moneyFormatter.format(
                              context.state.propertyQuote?.paymentRequests[1].amount
                            )}</td>
                          </tr>


                        </table>

                        <div className="border p-2 d-none ">
                          <div className="mb-2"><b id="payment-4">Your payment is secure</b></div>

                          <div class="text-center">
                            <div className="mt-3 mb-2">
                              <img src={PaymentsAccepted} alt="Payments Accepted" />
                            </div>
                            <PaypalButtonSwap values={values} errors={errors} promo={promo} discount={discount} search={search} sandbox={sandbox} handleSubmit={handleSubmit} setFormIsValid={setFormIsValid} />

                            <div className="mt-2">
                              <b>{moneyFormatter.format(
                                context.state.propertyQuote?.paymentRequests[0].amount
                              )}</b>
                              {' '} is the pending transaction amount.
                            </div>


                            <div className="text-muted text-small">
                              You won't be charged yet
                            </div>
                          </div>
                          <div className="text-center  col col-md-6 pt-2 small pl-0">
                            <i>A PayPal account is not required.</i> <button type="button" onClick={() => setGuestCheckoutModalIsOpen(true)} className="btn btn-hyperlink  text-primary p-0 align-baseline small"><i className="small">Click for more info</i></button>.
                          </div>
                        </div>


                      </div>}

                    </>
                  }


                  {
                    !context.state.propertyQuote.prepaidDiscount && context.state.propertyQuote?.paymentRequests.length > 1 && <>
                      <div >

                        <div>

                          <div >


                            <table className="my-2 w-100">

                              {context.state.propertyQuote?.promoDiscount ? <>


                                <tr>
                                  <td className="total7 font-weight-bold">Total</td>
                                  <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                    context.state.propertyQuote?.promoDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.promoDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                  )}</td>
                                </tr>
                                <tr>
                                  <td colSpan={2}>
                                    <button type="button" onClick={() => {
                                      onPriceDetailClick(true)
                                    }} className="btn btn-link text-primary p-0 d-flex items-center"><u style={{ marginRight: '.4rem' }}>Click here</u> for full rate details <ExternalLink width={14} className=" ml-1" /></button>
                                  </td>
                                </tr>
                              </> : <>

                                <tr>
                                  <td className="total8 font-weight-bold">Total</td>
                                  <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                    context.state.propertyQuote?.baseRate + context.state.propertyQuote?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                  )}</td>
                                </tr>
                                <tr>
                                  <td colSpan={2}>
                                    <button type="button" onClick={() => {
                                      onPriceDetailClick(true)
                                    }} className="btn btn-link text-primary p-0 d-flex items-center"><u style={{ marginRight: '.4rem' }}>Click here</u> for full rate details <ExternalLink width={14} className=" ml-1" /></button>
                                  </td>
                                </tr>
                              </>
                              }


                              <tr className="text-muted">
                                <td>Due now (50%)</td>
                                <td className="pl-3 text-right">{moneyFormatter.format(
                                  context.state.propertyQuote?.paymentRequests[0].amount
                                )}</td>
                              </tr>
                              <tr className="text-muted">
                                <td>Due {moment(context.state.propertyQuote?.paymentRequests[1].date)
                                  .format('dddd')
                                  .substring(0, 3) +
                                  ', ' +
                                  moment(context.state.propertyQuote?.paymentRequests[1].date).format('MMM DD, YYYY')

                                } (50%)</td>
                                <td className="pl-3 text-right">{moneyFormatter.format(
                                  context.state.propertyQuote?.paymentRequests[1].amount
                                )}</td>
                              </tr>
                            </table>

                            <div className="border p-2 d-none ">
                              <div className="mb-2"><b id="payment-5">Your payment is secure</b></div>

                              <div className="text-center">
                                <div className="mt-3 mb-2">
                                  <img src={PaymentsAccepted} alt="Payments Accepted" />
                                </div>
                                <PaypalButtonSwap values={values} errors={errors} promo={promo} discount={discount} search={search} sandbox={sandbox} handleSubmit={handleSubmit} setFormIsValid={setFormIsValid} />

                                <div className="mt-2">

                                  <b><>
                                    {moneyFormatter.format(
                                      context.state.propertyQuote?.paymentRequests[0].amount
                                    )}
                                  </></b>
                                  {' '} is the pending transaction amount.
                                </div>


                                <div className="text-muted text-small">
                                  You won't be charged yet
                                </div>
                              </div>
                              <div className="text-center  col col-md-6 pt-2 small pl-0">
                                <i>A PayPal account is not required.</i> <button type="button" onClick={() => setGuestCheckoutModalIsOpen(true)} className="btn btn-hyperlink  text-primary p-0 align-baseline small"><i className="small">Click for more info</i></button>.
                              </div>

                            </div>



                          </div>
                        </div>


                      </div>
                    </>}



                </div>
              );
            }
            }</PropertyQuoteConsumer>

        </div>
        <Modal
          // expanded={true}
          disableFocusTrap={true}
          isOpen={priceDetailModalIsOpen}
          title={'Price Details'}
          onClose={() => onPriceDetailClick(false)}
          onOpen={() => {

            $('[data-toggle="popover"]').popover({
              container: '.modal-body',
            });
          }}
        >
          <>
            <div className="d-flex justify-content-between mb-1plus">
              <span>{propertyQuote.totalNights} x nights</span>
              <span style={{ 'textDecoration': propertyQuote.promoDiscount ? 'line-through' : '' }}>{moneyFormatter.format(propertyQuote.baseRate)}</span>
            </div>

            {propertyQuote.promoDiscount &&
              <div className="d-flex justify-content-between mb-1plus text-success font-weight-bold">
                <span>{propertyQuote.totalNights > 30 ? <>Promo</> : <>Promotional</>} Rate ({propertyQuote.promoDiscount.percent}% off) {propertyQuote.totalNights > 30 && <><i className="font-weight-normal" style={{ color: '#000' }}>1st 30 nights only</i> <a
                  style={{ cursor: 'pointer' }}
                  tabIndex="0"
                  id="more-info"
                  className="ml-1plus popover-dismiss"

                  data-toggle="popover"
                  data-placement="top"
                  data-html="true"
                  data-template='<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
                  data-content={`Includes <b>${propertyQuote.promoDiscount.percent}%</b> discount on the owner's rate for the first 30 nights of your stay.`}
                >
                  <img src="/more-info.svg" width="22" height="22" />
                </a></>}</span>
                <span>{moneyFormatter.format(propertyQuote.promoDiscount.baseRateDiscountApplied)}</span>
              </div>}
            {prepaidDiscountRequested == 'YES' && propertyQuote.prepaidDiscount && <div className="d-flex justify-content-between mb-1plus  text-success font-weight-bold">
              <span>Full Payment Discount</span>
              <span >-{moneyFormatter.format(
                (propertyQuote.promoDiscount ? propertyQuote.promoDiscount.baseRateDiscountApplied : propertyQuote.baseRate) / propertyQuote.prepaidDiscount.percent
              )}</span>




            </div>}
            <div className="d-flex justify-content-between mb-1plus">
              <span>Cleaning & Restocking Fee</span>
              <span>{moneyFormatter.format(propertyQuote.cleaningFee)}</span>
            </div>
            <div className="d-flex justify-content-between mb-1plus">
              <span>Administrative Fee</span>
              <span>
                {moneyFormatter.format(propertyQuote.administrativeFee)}
              </span>
            </div>
            {numberOfPets > 0 && (
              <div className="d-flex justify-content-between mb-1plus">
                <span>
                  Pet Fee {numberOfPets > 1 && <>({numberOfPets} pets)</>}
                </span>
                <span>{moneyFormatter.format(propertyQuote.petFee)}</span>
              </div>
            )}
            <div className="d-flex justify-content-between mb-1plus">
              <span>FL & County Sales Tax ({propertyQuote.property.stateTaxRate}%)</span>
              {discountNode ? <span>{moneyFormatter.format(discountNode.tax?.stateTax)}</span> : <span>{moneyFormatter.format(propertyQuote.tax.stateTax)}</span>}
            </div>
            <div className="d-flex justify-content-between mb-1plus">
              <span>Hotel / Bed / Tourism Tax ({propertyQuote.property.countyTaxRate}%)</span>
              {discountNode ? <span>{moneyFormatter.format(discountNode.tax?.countyTax)}</span> : <span>{moneyFormatter.format(propertyQuote.tax.countyTax)}</span>}

            </div>

            <div className="d-flex justify-content-between mb-1plus border-top mt-2 pt-2">
              <b>Total</b>
              {discountNode ? <b>{moneyFormatter.format(discountNode.rate)}</b> : <b>{moneyFormatter.format(propertyQuote.rate)}</b>}
            </div>
            <div className="mt-2 small"><i>Rates are valid only for the next 24 hours. Rates recalibrate nightly, based on supply & demand. Cancel any time at least 30 days prior to check-in, for a full refund.</i></div>
            {propertyQuote.promoDiscount && propertyQuote.totalNights > 30 && <div className="small">Promotional rate discounts are applied to the first 30 nights.</div>}
          </>
        </Modal>
      </div>
    </>
  );
};

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const ReserveDatesPage = ({
  data,
  pageContext,
  search,
  location,
  ...props
}) => {
  // const { siteTagGroup } = data.bhip || {};
  // const { siteTags } = siteTagGroup || {};
  // const [priceDetailModalIsOpen, setPriceDetailModalIsOpen] = useState(false);
  const [guestNumbersLabel, setGuestNumbersLabel] = useState(null);
  const [formIsValid, setFormIsValid] = useState(false);
  const [numberOfPets, setNumberOfPets] = useState(null);
  const [numberOfAdults, setNumberOfAdults] = useState(0);
  // console.log('numberOfPets1', numberOfPets);
  const [priceDetailModalIsOpen, setPriceDetailModalIsOpen] = useState(false);
  const [guestCheckoutModalIsOpen, setGuestCheckoutModalIsOpen] = useState(false);
  const params = new URLSearchParams(location?.search);
  const promo = params.get('promo') ? params.get('promo') : Cookies.get('promo');
  const discount = params.get('discount') ? params.get('discount') : Cookies.get('discount');
  const sandbox = params.get('sandbox') ? params.get('sandbox') : false;
  // console.log('props', props);

  const siteTags = data?.bhip?.siteTagGroup?.siteTags || [];
  const property = data?.bhip?.property || {};
  const { sitePage } = data?.bhip;
  const siteMeta = getSiteMeta();

  return (
    <Layout searchbarLabel="Reserve Your Dates" hideSearchBar={true}>
      <PropertyQuoteProvider>
        <SEO
          title={sitePage.seoTitle}
          description={sitePage.seoDescription}
          headTags={sitePage.headTags}
        />

        <Formik
          validationSchema={validationSchema}
          initialValues={{
            propertyId: property.id,
            checkIn: search.checkin,
            checkOut: search.checkout,
            // firstName: '',
            // lastName: '',
            emailAddress: '',
            // phoneNumber: '',
            // address: '',
            // city: '',
            // state: '',
            // postalCode: '',
            // country: '',
            // additionalDetails: '',
            // numberOfAdults: 0,
            // numberOfChildren: 0,
            // numberOfPets: 0,
            // numberOfVehicles: 0,
            // numberOfVisitors: 0,
          }}
          onSubmit={async (
            values,
            { setSubmitting, setStatus, setErrors, resetForm }
          ) => {
            console.log('values1', values);
          }}
        >
          {formikProps => {
            const {
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              status,
              resetForm,
              submitCount,
              setStatus,
            } = formikProps;

            // console.log('values', values);
            // console.log('errors', errors);
            // console.log('touched', touched);

            return (
              <form id="reserveForm" onSubmit={handleSubmit}>

                <div className="container mt-3">
                  <p id="top-paragraph" className="d-none d-md-block">
                    Reserve your dates at {property.name}. Upon completing your payment,
                    we will contact you to confirm your details and finalize your
                    reservation.
                  </p>

                  <div className="row  flex-column-reverse flex-md-row">
                    <div className="col col-12 col-md-7 col-xl-8">
                      <div className="border-bottom mb-2 pb-2">
                        <h2 className="m-0 d-flex">
                          <div className={styles.chevron}>1</div> Reservation Details
                        </h2>
                      </div>


                      <div className="form-group">
                        <label htmlFor="email">
                          <b>Dates</b>
                        </label>
                        <div>
                          {moment(search.checkin)
                            .format('dddd')
                            .substring(0, 3) +
                            ', ' +
                            moment(search.checkin).format('MMM DD, YYYY')}{' - '}
                          {moment(search.checkout)
                            .format('dddd')
                            .substring(0, 3) +
                            ', ' +
                            moment(search.checkout).format('MMM DD, YYYY')}
                          <span className="text-muted ml-2">(Arrive after 5pm; Depart before 11am)</span>
                        </div>
                      </div>




                      <div className="form-group">
                        <div className="border-bottom mt-4 mb-1 pb-2">
                          <h2 className="m-0 d-flex">
                            <div className={styles.chevron}>2</div> Your Details
                          </h2>
                        </div>


                        <div className="form-group mt-3 mb-4">
                          {/* <label htmlFor="email">
                            <b>Email</b>
                            <span className="text-danger ml-1">*</span>
                          </label> */}
                          <input
                            type="email"
                            onChange={handleChange}
                            className="form-control rounded"
                            name="emailAddress"
                            id="email"
                            placeholder="Email Address"
                            required
                          />
                          {/* We'll send a confirmation email to this address, please
                          ensure it's accurate. */}
                        </div>

                      </div>
                      <PropertyQuoteConsumer>
                        {context => {
                          // console.log('context', context);
                          return context.state.propertyQuote ? (
                            <>
                              <div className="form-group">
                                <div className="border-bottom mt-2 mb-3 pb-2">
                                  <h2 className="m-0 d-flex">
                                    <div className={styles.chevron}>3</div> Cancellation Policy
                                  </h2>
                                </div>



                                <p>

                                  <b>100% FREE Cancellation Guarantee:</b> Cancel up to 30
                                  days before check-in for a full refund.
                                  {/* <br />
                                  <br />
                                  <b>50% Partial Refund Option:</b> Cancel 14-30 days
                                  before check-in for a generous 50% refund. */}

                                </p>



                              </div >


                              <div className="form-group _d-none">

                                <div className="border-bottom mt-3 mb-3 pb-2">
                                  <h2 className="m-0 d-flex">
                                    <div><div className={styles.chevron}>4</div></div> Secure Your Dates with Payment
                                  </h2>
                                </div>

                                {
                                  context.state.propertyQuote.prepaidDiscount && context.state.propertyQuote?.paymentRequests.length > 1 && <>
                                    <div className=" pb-2">
                                      <div><b>Payment Options</b></div>
                                      <div>

                                        <div className="mt-1plus custom-control custom-radio custom-control-inline">
                                          <input
                                            type="radio"
                                            id="prepaidDiscountOption1"
                                            name="prepaidDiscountOption"
                                            className="custom-control-input"
                                            checked={context.state.prepaidDiscount == 'YES'}
                                            value="YES"
                                            onChange={e => {
                                              console.log('change', e)
                                              console.log('context', context)
                                              context.setPrepaidDiscount('YES');
                                              //  handleChange(e);
                                            }}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="prepaidDiscountOption1"
                                          >
                                            Full Payment: Save 10%
                                            {context.state.propertyQuote?.promoDiscount ? <> more on the promo rate</> : <> on the owner's rate</>}
                                          </label>
                                        </div>
                                      </div>


                                      <div>
                                        <div className="mt-1plus  custom-control custom-radio custom-control-inline">
                                          <input
                                            type="radio"
                                            id="prepaidDiscountOption2"
                                            name="prepaidDiscountOption"
                                            className="custom-control-input"
                                            checked={context.state.prepaidDiscount == 'NO'}
                                            value="NO"
                                            onChange={e => {
                                              console.log('change', e)
                                              context.setPrepaidDiscount('NO');
                                              // handleChange(e);
                                            }}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="prepaidDiscountOption2"
                                          >
                                            Split Payment: Half now, half 45 days pre-arrival
                                          </label>
                                        </div>
                                      </div>
                                    </div>



                                    {context.state.prepaidDiscount == 'NO' && context.state.propertyQuote?.paymentRequests.length > 1 && <div className="d-md-none">

                                      <table className="my-2 w-100">



                                        {context.state.propertyQuote?.promoDiscount ?
                                          <>


                                            <tr>
                                              <td className="total5 font-weight-bold">Total</td>
                                              <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                context.state.propertyQuote?.promoDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.promoDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                              )}</td>
                                            </tr>
                                            <tr>
                                              <td colSpan={2}>
                                                <button type="button" onClick={() => {
                                                  setPriceDetailModalIsOpen(true)
                                                }} className="btn btn-link text-primary p-0 d-flex items-center"><u style={{ marginRight: '.4rem' }}>Click here</u> for full rate details <ExternalLink width={14} className=" ml-1" /></button>
                                              </td>
                                            </tr>

                                          </> : <>

                                            <tr>
                                              <td className="total6 font-weight-bold">Total</td>
                                              <td className="font-weight-bold pl-3 text-right">{moneyFormatter.format(
                                                context.state.propertyQuote?.baseRate + context.state.propertyQuote?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                              )}</td>
                                            </tr>
                                            <tr>
                                              <td colSpan={2}>
                                                <button type="button" onClick={() => {
                                                  setPriceDetailModalIsOpen(true)
                                                }} className="btn btn-link text-primary p-0 d-flex items-center"><u style={{ marginRight: '.4rem' }}>Click here</u> for full rate details <ExternalLink width={14} className=" ml-1" /></button>
                                              </td>
                                            </tr>
                                          </>}

                                        <tr className="text-muted">
                                          <td>Due now (50%)</td>
                                          <td className="  pl-3 text-right">{moneyFormatter.format(
                                            context.state.propertyQuote?.paymentRequests[0].amount
                                          )}</td>
                                        </tr>
                                        <tr className="text-muted">
                                          <td>Due {
                                            moment(context.state.propertyQuote?.paymentRequests[1].date)
                                              .format('dddd')
                                              .substring(0, 3) +
                                            ', ' +
                                            moment(context.state.propertyQuote?.paymentRequests[1].date).format('MMM DD, YYYY')
                                          } (50%)</td>
                                          <td className="  pl-3 text-right">{moneyFormatter.format(
                                            context.state.propertyQuote?.paymentRequests[1].amount
                                          )}</td>
                                        </tr>


                                      </table>



                                    </div>}


                                  </>}

                                <Modal
                                  // expanded={true}
                                  title={'Payment Help'}
                                  disableFocusTrap={true}
                                  isOpen={guestCheckoutModalIsOpen}

                                  onClose={() => setGuestCheckoutModalIsOpen(false)}
                                >
                                  <>
                                    Security experts advise against sharing your personal financial information - such as credit-card numbers - with internet websites operated by small-to-medium-sized companies or family-run businesses like Beach Houses in Paradise. Reason: Such operations lack the expertise or financial resources to safely protect your financial details from hackers. For this reason, Beach Houses in Paradise partners with PayPal for reservation payment processing. It is not necessary to have a PayPal account to book your beach house.

                                    <div className="mt-2 mb-2">
                                      The illustration below shows how to book without having a PayPal account. (Scroll down to the very bottom.)
                                    </div>
                                    <img src={GuestCheckoutGuide} />
                                  </>
                                </Modal>
                                {
                                  !context.state.propertyQuote.prepaidDiscount && context.state.propertyQuote?.paymentRequests.length == 1 && <>
                                    <div >

                                      <div>

                                        <div >

                                          {context.state.propertyQuote?.promoDiscount ? <>

                                            <div className="border p-2 ">
                                              <div className="mb-2"><b id="payment-6">Your payment is secure</b></div>

                                              <div class="text-center  col col-md-6 pl-0">
                                                <div className="mt-3 mb-2">
                                                  <img src={PaymentsAccepted} alt="Payments Accepted" />
                                                </div>
                                                <PaypalButtonSwap discount={discount} values={values} errors={errors} promo={promo} search={search} sandbox={sandbox} handleSubmit={handleSubmit} setFormIsValid={setFormIsValid} />



                                                <div className="mt-2">
                                                  <b>{moneyFormatter.format(
                                                    context.state.propertyQuote?.promoDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.promoDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                  )}</b>
                                                  {' '} is the pending transaction amount.
                                                </div>
                                                <div className="text-muted text-small">
                                                  You won't be charged yet
                                                </div>
                                              </div>
                                              <div className="text-center  col col-md-6 pt-2 small pl-0">
                                                <i>A PayPal account is not required.</i> <button type="button" onClick={() => setGuestCheckoutModalIsOpen(true)} className="btn btn-hyperlink  text-primary p-0 align-baseline small"><i className="small">Click for more info</i></button>.
                                              </div>
                                            </div>


                                          </> : <>
                                            <div className="border p-2 ">
                                              <div className="mb-2"><b id="payment-7">Your payment is secure</b></div>

                                              <div class="text-center  col col-md-6 pl-0">
                                                <div className="mt-3 mb-2">
                                                  <img src={PaymentsAccepted} alt="Payments Accepted" />
                                                </div>
                                                <PaypalButtonSwap discount={discount} values={values} errors={errors} promo={promo} search={search} sandbox={sandbox} handleSubmit={handleSubmit} setFormIsValid={setFormIsValid} />

                                                <div className="my-2">
                                                  <b>{moneyFormatter.format(
                                                    context.state.propertyQuote?.paymentRequests[0].amount
                                                  )}</b>
                                                  {' '} is the pending transaction amount.
                                                </div>
                                                <div className="text-muted text-small">
                                                  You won't be charged yet
                                                </div>
                                              </div>
                                              <div className="text-center  col col-md-6 pt-2 small pl-0">
                                                <i>A PayPal account is not required.</i> <button type="button" onClick={() => setGuestCheckoutModalIsOpen(true)} className="btn btn-hyperlink  text-primary p-0 align-baseline small"><i className="small">Click for more info</i></button>.
                                              </div>
                                            </div>
                                          </>}
                                        </div>
                                      </div>
                                    </div>
                                  </>}

                                {
                                  context.state.propertyQuote.prepaidDiscount && context.state.propertyQuote?.paymentRequests.length > 1 && <>

                                    {context.state.prepaidDiscount == 'YES' && context.state.propertyQuote?.paymentRequests.length > 1 && <div >


                                      <div className="border p-2 ">
                                        <div className="mb-2"><b id="payment-8">Your payment is secure</b></div>

                                        <div className="text-center  col col-md-6 pl-0">
                                          <div className="mt-3 mb-2">
                                            <img src={PaymentsAccepted} alt="Payments Accepted" />
                                          </div>
                                          <PaypalButtonSwap discount={discount} values={values} errors={errors} promo={promo} search={search} sandbox={sandbox} handleSubmit={handleSubmit} setFormIsValid={setFormIsValid} />


                                          <div className="mt-2">

                                            <b>{context.state.propertyQuote?.promoDiscount ?
                                              <>{moneyFormatter.format(
                                                context.state.propertyQuote?.prepaidDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.prepaidDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                              )} </> : <>
                                                {moneyFormatter.format(
                                                  context.state.propertyQuote?.prepaidDiscount?.baseRateDiscountApplied + context.state.propertyQuote?.prepaidDiscount?.tax?.lodgingTax + context.state.propertyQuote?.fees
                                                )}
                                              </>}</b>
                                            {' '} is the pending transaction amount.
                                          </div>   <div className="text-muted text-small">
                                            You won't be charged yet
                                          </div>
                                        </div>
                                        <div className="text-center  col col-md-6 pt-2 small pl-0">
                                          <i>A PayPal account is not required.</i> <button type="button" onClick={() => setGuestCheckoutModalIsOpen(true)} className="btn btn-hyperlink  text-primary p-0 align-baseline small"><i className="small">Click for more info</i></button>.
                                        </div>

                                      </div>

                                    </div>}
                                    {context.state.prepaidDiscount == 'NO' && context.state.propertyQuote?.paymentRequests.length > 1 && <div>

                                      <div className="border p-2 ">
                                        <div className="mb-2"><b id="payment-9">Your payment is secure</b></div>

                                        <div class="text-center  col col-md-6 pl-0">
                                          <div className="mt-3 mb-2">
                                            <img src={PaymentsAccepted} alt="Payments Accepted" />
                                          </div>
                                          <PaypalButtonSwap discount={discount} values={values} errors={errors} promo={promo} search={search} sandbox={sandbox} handleSubmit={handleSubmit} setFormIsValid={setFormIsValid} />


                                          <div className="mt-2">
                                            <b>{moneyFormatter.format(
                                              context.state.propertyQuote?.paymentRequests[0].amount
                                            )}</b>
                                            {' '} is the pending transaction amount.
                                          </div>  <div className="text-muted text-small">
                                            You won't be charged yet
                                          </div>
                                        </div>
                                        <div className="text-center  col col-md-6 pt-2 small pl-0">
                                          <i>A PayPal account is not required.</i> <button type="button" onClick={() => setGuestCheckoutModalIsOpen(true)} className="btn btn-hyperlink  text-primary p-0 align-baseline small"><i className="small">Click for more info</i></button>.
                                        </div>
                                      </div>


                                    </div>}

                                  </>
                                }


                                {
                                  !context.state.propertyQuote.prepaidDiscount && context.state.propertyQuote?.paymentRequests.length > 1 && <>


                                    <div className="border p-2 ">
                                      <div className="mb-2"><b id="payment-10">Your payment is secure</b></div>

                                      <div className="text-center  col col-md-6 pl-0">
                                        <div className="mt-3 mb-2">
                                          <img src={PaymentsAccepted} alt="Payments Accepted" />
                                        </div>
                                        <PaypalButtonSwap discount={discount} values={values} errors={errors} promo={promo} search={search} sandbox={sandbox} handleSubmit={handleSubmit} setFormIsValid={setFormIsValid} />


                                        <div className="mt-2">

                                          <b><>
                                            {moneyFormatter.format(
                                              context.state.propertyQuote?.paymentRequests[0].amount
                                            )}
                                          </></b>
                                          {' '} is the pending transaction amount.
                                        </div>
                                        <div className="text-muted text-small">
                                          You won't be charged yet
                                        </div>
                                      </div>
                                      <div className="text-center  col col-md-6 pt-2 small pl-0">
                                        <i>A PayPal account is not required.</i> <button type="button" onClick={() => setGuestCheckoutModalIsOpen(true)} className="btn btn-hyperlink  text-primary p-0 align-baseline small"><i className="small">Click for more info</i></button>.
                                      </div>

                                    </div>



                                  </>}



                              </div >


                            </>
                          ) : null;
                        }}
                      </PropertyQuoteConsumer >
                      <div className="pt-1 d-md-none">
                        <PropertyReviews propertyId={property.id} labelAsReviews={true} />
                      </div>
                      <div className={`border-top form-group pt-3 ${styles.showBorderMedium}`}>
                        <b>What others say about Beach Houses in Paradise (Google Reviews)</b>
                        <p className="my-2">For over 20 years, we've delivered amazing vacation experiences to discerning &amp; knowledgeable travelers</p>

                        <Reviews />
                      </div>


                      {/* submitCount = {submitCount} <br />
                      touched = {Object.keys(touched)}
                      errors = {Object.keys(errors)} */}

                      {/* <button type="submit" className="btn btn-primary mb-4">
                        Continue to Paypal
                      </button> */}

                      {/* <div>{moneyFormatter.format(propertyQuote.rate)}</div> */}
                      {/* <div className="border p-2  d-md-none">
                        <div className="mb-2"><b>Your payment is secure</b></div>
                        <div className="my-2">
                          <b>{moneyFormatter.format(
                            context.state.propertyQuote?.paymentRequests[0].amount
                          )}</b>
                          {' '} is the pending transaction amount.
                        </div>
                        <div class="text-center">
                          <PaypalButtonSwap values={values} errors={errors} promo={promo} search={search} sandbox={sandbox} handleSubmit={handleSubmit} setFormIsValid={setFormIsValid} />
                          <div className="text-muted text-small">
                            You won't be charged yet
                          </div>
                        </div>

                      </div> */}

                      <div className={`border-top form-group pt-2 `}>
                        <b>House Rules</b>
                        <ul className="mt-2">
                          <li>Minimum age to rent is 35.</li>
                          <li>
                            All events must be approved in advance prior to making
                            initial reservation.
                          </li>
                          <li>
                            Pets allowed: Up to 3 cats or dogs of any size per home.

                          </li>
                          <li>
                            Because little hands touch floors, please leave your shoes
                            at the door.
                          </li>
                          <li>
                            After fun in ocean/river, please use the outdoor showers to
                            rinse off sand
                            {(property.amenities || []).find(amenity => amenity.key == 'pool' || amenity.key == 'hottub') ? <span> before enjoying the hot tub or pool (pets, too!).</span> : <span>.</span>}
                            {console.log('property3', property)}
                          </li>
                        </ul>
                      </div>
                      <div className="form-group mt-3">
                        <b>IMPORTANT LEGAL NOTICE: OUR LIABILITY IS LIMITED</b>

                        <div className="my-2">
                          Your reservation with Beach Houses in Paradise is subject to
                          the attached agreement, which LIMITS OUR LIABILITY:
                        </div>

                        <div className="my-2">PLEASE READ CAREFULLY:</div>

                        <div className="my-2">
                          When you rent a Beach House in Paradise, the home becomes
                          ~your~ home for the duration of your stay . . . your home away
                          from home (or your "Vacation Home"). Just as when you bring
                          guests into your own home, you agree to accept responsibility
                          for the safety and welfare of anyone you bring or invite into
                          your Vacation Home. This includes -- but is not limited to --
                          minors, seniors, pets, and anyone in your group with limited
                          motion, vision, hearing, health or other ailments. This means
                          that you agree to indemnify and hold harmless Beach Houses in
                          Paradise (and all individuals and entities connected to, and
                          involved with, the administration and operation of your
                          Vacation Home) from all liability and claims that may arise
                          from the use of the home by you and your guests. For
                          additional details, please see the terms & conditions attached
                          to this payment invoice.
                        </div>
                        <a
                          href="https://images.beachhousesinparadise.com/uploads/docs/lease-agreement-v2019-02-18.pdf"
                          target="_blank"
                        >
                          Lease Agreement
                        </a>
                      </div>
                    </div>

                    <div className="col col-12  col-md-5 col-xl-4">
                      <aside>
                        <PropertyQuoteConsumer>
                          {context => {
                            return (<QuotePanel
                              search={search}
                              setFormIsValid={setFormIsValid}
                              property={property}
                              location={location}
                              numberOfPets={numberOfPets}
                              guestNumbersLabel={guestNumbersLabel}
                              propertyId={property.id}
                              values={values}
                              errors={errors}
                              promo={promo}
                              discount={discount}
                              handleSubmit={handleSubmit}
                              sandbox={sandbox}
                              priceDetailModalIsOpen={priceDetailModalIsOpen}
                              prepaidDiscountRequested={context.state?.prepaidDiscount}
                              onPriceDetailClick={(openOrClose) => {
                                setPriceDetailModalIsOpen(openOrClose)
                              }}
                            />)
                          }}</PropertyQuoteConsumer>
                        <div className="pt-1 d-none d-md-block">
                          <PropertyReviews propertyId={property.id} labelAsReviews={true} scrollable={false} />
                        </div>
                      </aside>
                    </div>
                  </div>


                </div>
              </form>
            );
          }}
        </Formik>
      </PropertyQuoteProvider>
    </Layout >
  );
};
export const query = graphql`
  query ReserveDates($slug: String, $propertyForeignKey: Int) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...SitePageData
      }
      property(where: { id: $propertyForeignKey }) {
        id
        additionalMessaging
        priceOffSeason
        label
        key
        name
        description
        sleeps
        bedrooms
        bathrooms
        type
        latitude
        longitude
        group
        streetAddress1
        amenities {
          name
          key
        }
        sitePage {
          slug
          status
          featuredImageCollection {
            files {
              tiny: version(where: { key: "tiny" }) {
                fullUrl
              }
              medium: version(where: { key: "medium" }) {
                fullUrl
              }
            }
          }
        }
      }
    }
  }
`;

export default WithLocation(ReserveDatesPage);
