import React, { useEffect, useState, createRef } from 'react';
import { withApollo, WithApolloClient } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './styles.scss';
const PROPERTY_REVIEWS = gql`
  query GuestReservationReviewConnection($propertyId: Int) {
    guestReservationReviewConnection(
      where: { propertyId: $propertyId, siteStatus: FEATURED, fiveStarRating_gte: 4 }
    ) {
      aggregate {
        avg {
          fiveStarRating
        }
      }
      edges {
        node {
          id
          headline
          body
          firstName
          lastName
          reviewDate
          fiveStarRating
          reviewResponse
          property {
            id
            name
          }
        }
      }
    }
  }
`;
// import './styles.scss';
// import StarImg from '../../../../../images/star.svg';

const StarIcons = ({ numberOfStars }) => {
  return Array.from({ length: Number(numberOfStars) }).map((_, index) => {
    return <img width="20" key={index} src="/rating-on.svg" />; //<img key={index} width="20" src={StarImg} />;
  });
};
const PropertyReviews = ({ propertyId, scrollable, labelAsReviews = false }) => {
  //console.log('props', props);
  const { data, error, loading } = useQuery(PROPERTY_REVIEWS, {
    variables: { propertyId },
  });
  const [viewMoreClicked, setViewMoreClicked] = useState(false);

  const [expandedOwnerResponses, setExpandedOwnerResponses] = useState({});
  const showOwnerResponse = (index) => {

    setExpandedOwnerResponses(prevStates => {
      prevStates[index] = true;

      return { ...prevStates };
    });
  };

  // const [reviews, setViewMoreClicked] = useState(false);
  let scrollBarRef = createRef();
  useEffect(() => {
    if (loading == false && scrollBarRef.current) {
      const jumpValue = window.location.hash;

      if (jumpValue == '#guestreviews') {
        location.hash = jumpValue
      }
      scrollBarRef.current.updateScroll(); // Used to invoke scrollbar so it is visible by default (via css.)
    }
    // console.log('loading', loading); //.updateScroll()
    // console.log('scrollBarRef', scrollBarRef); //.updateScroll()
    // console.log('scrollBarRef', scrollBarRef.current); //.updateScroll()
  }, [loading]);
  // if (loading)
  //   return (
  //     <div
  //       className="pt-2"
  //       style={{ height: '40px', textAlign: 'center', width: '100%' }}
  //     >
  //       LOADING....
  //     </div>
  //   );
  if (error) return null;
  if (
    !loading &&
    data &&
    data.guestReservationReviewConnection.aggregate.avg.fiveStarRating == null
  )
    return null;
  return (
    <>
      <div className=" mt-2">
        <div className={labelAsReviews ? 'h3 font-weight-bold' : 'h2'} id="guestreviews">
          <span className="mr-2" style={{ verticalAlign: 'text-bottom' }}>
            {labelAsReviews ? <>{data?.guestReservationReviewConnection?.edges?.length && <>What others say about "{data.guestReservationReviewConnection.edges[0].node.property.name}"</>}</> : <>Property Reviews</>}
          </span>
          {!loading && (
            <>
              <br className={scrollable == false ? '' : 'd-md-none'} />
              <span
                style={{
                  fontSize: '15px',
                  fontWeight: 'normal',
                }}
              >
                {data.guestReservationReviewConnection.aggregate.avg.fiveStarRating.toFixed(
                  1
                )}{' '}
                Rating - {data.guestReservationReviewConnection.edges.length}{' '}
                {labelAsReviews ? <>Reviews</> : <>Reviews</>}
              </span>
            </>
          )}
        </div>
        {loading ? (
          <div
            className="pt-2"
            style={{ height: '40px', textAlign: 'center', width: '100%' }}
          >
            LOADING....
          </div>
        ) : (
          <div className="pr-2 mb-2" style={{ height: scrollable == false ? 'auto' : '500px' }}>
            <PerfectScrollbar ref={scrollBarRef}>
              {data.guestReservationReviewConnection.edges
                .filter(item => item.node.fiveStarRating == 5 || (item.node.fiveStarRating == 4 && item.node.reviewResponse))
                .slice(
                  0,
                  viewMoreClicked
                    ? data.guestReservationReviewConnection.edges.length
                    : 5
                )
                .map(({ node }) => {
                  //   console.log('node', node);
                  const {
                    id,
                    firstName,
                    lastName,
                    reviewDate,
                    fiveStarRating,
                    headline,
                    reviewResponse,
                    body,
                  } = node;
                  return (
                    <div className="mb-2 pr-2">
                      <div className="mb-1">
                        <b style={{ fontWeight: 600 }}>
                          {firstName} {`${lastName}`.substr(0, 1)}
                        </b>
                      </div>
                      <div className="d-flex justify-content-between mb-1plus">
                        <div>
                          <StarIcons numberOfStars={fiveStarRating} />{' '}
                          {/* <span
                            className="mr-1"
                            style={{ verticalAlign: 'middle' }}
                          >
                            {fiveStarRating}.0
                          </span> */}
                        </div>
                        <div>
                          {/* {' '}
                          {new Date(reviewDate)
                            .toLocaleDateString('en', {
                              year: 'numeric',
                              month: 'numeric',
                              day: '2-digit',
                            })
                            .replace(/\//g, '-')} */}
                        </div>
                      </div>
                      <div>
                        <b style={{ fontWeight: 600 }}>{headline}</b>
                        <div>{body}</div>
                        {reviewResponse && <div>
                          <button className={`${expandedOwnerResponses[id] ? 'd-none' : ''} btn btn-hyperlink text-primary pl-0`} onClick={() => {
                            showOwnerResponse(id)
                          }}>View Owner Response</button>
                          <div className={`${expandedOwnerResponses[id] ? '' : 'd-none'} mt-2`}><b>Owner Response</b>: {reviewResponse}</div>
                        </div>}
                      </div>
                      <hr className="mt-2 mb-1" />
                    </div>
                  );
                })}
              {!viewMoreClicked && (
                <div className="text-center">
                  <button
                    className="btn btn-link"
                    onClick={() => {
                      setViewMoreClicked(true);
                      scrollBarRef.current.updateScroll();
                    }}
                  >
                    View More
                  </button>
                </div>
              )}
            </PerfectScrollbar>
          </div>
        )}
      </div>
    </>
  );
};

export default PropertyReviews;
