import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Mutation, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import FormFeedback from '../../../components/Forms/FormFeedback';
import { objectIsEmpty } from '../../../util/helpers';

const CREATE_CONTACT_REQUEST = gql`
  mutation CreateAltSiteContactRequest($data: CreateAltSiteContactRequestInput!) {
    createAltSiteContactRequest(data: $data) {
      id
      createdAt
    }
  }
`;

const validationSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  name: Yup.string()
    .required('First & Last Name is required'),
  phone1: Yup.string()
    .required('Phone #1 is required'),
  phone2: Yup.string(),
  brokerAffiliation: Yup.string(),
  message: Yup.string()
    .required('Message is required')
});

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

const AltSiteContactPage = () => {
  const [formStatus, setFormStatus] = useState(null);
  const [createContactRequest, { loading: mutationLoading }] = useMutation(CREATE_CONTACT_REQUEST);

  useEffect(() => {
    function sendHeightInfo() {
      window.parent.postMessage({
        type: 'setHeight',
        height: document.body.scrollHeight
      }, '*');
    }
    sendHeightInfo();
    window.addEventListener('resize', debounce(sendHeightInfo, 250))
  }, [])
  const initialValues = {
    emailAddress: '',
    name: '',
    phone1: '',
    phone2: '',
    brokerAffiliation: '',
    message: ''
  };

  const handleSubmit = async (values, { setSubmitting, setStatus, setErrors, resetForm }) => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const data = {
        ...values,
        domain: urlParams.get('domain') || 'not specified'
      };

      const result = await createContactRequest({
        variables: { data }
      });

      if (result?.data?.createAltSiteContactRequest?.id) {
        setFormStatus({ success: true });
        window.parent.postMessage({
          type: 'setHeight',
          height: document.body.scrollHeight
        }, '*');
        resetForm();
      }
    } catch (err) {
      console.error('Error submitting form:', err);
      setStatus({
        error: 'There was a problem with your submission. Please check your data and try again.'
      });
      setErrors({
        message: 'There was a problem with your submission. Please check your data and try again.'
      });
    }
    setSubmitting(false);
  };

  return (
    <div >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting, status, submitCount }) => (
          <Form>
            <div className="mb-3">
              <label htmlFor="emailAddress" className="form-label">
                Email <span className="text-danger">*</span>
              </label>
              <Field
                type="email"
                id="emailAddress"
                name="emailAddress"
                className={`form-control ${errors.emailAddress && touched.emailAddress ? 'is-invalid' : ''}`}
              />
              {errors.emailAddress && touched.emailAddress && (
                <div className="invalid-feedback">{errors.emailAddress}</div>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                First & Last Name <span className="text-danger">*</span>
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
              />
              {errors.name && touched.name && (
                <div className="invalid-feedback">{errors.name}</div>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="phone1" className="form-label">
                Phone #1 <span className="text-danger">*</span>
              </label>
              <Field
                type="tel"
                id="phone1"
                name="phone1"
                className={`form-control ${errors.phone1 && touched.phone1 ? 'is-invalid' : ''}`}
              />
              {errors.phone1 && touched.phone1 && (
                <div className="invalid-feedback">{errors.phone1}</div>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="phone2" className="form-label">
                Phone #2
              </label>
              <Field
                type="tel"
                id="phone2"
                name="phone2"
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="brokerAffiliation" className="form-label">
                Broker / Realtor Affiliation (if any)
              </label>
              <Field
                type="text"
                id="brokerAffiliation"
                name="brokerAffiliation"
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message <span className="text-danger">*</span>
              </label>
              <Field
                as="textarea"
                id="message"
                name="message"
                className={`form-control ${errors.message && touched.message ? 'is-invalid' : ''}`}
                rows="4"
              />
              {errors.message && touched.message && (
                <div className="invalid-feedback">{errors.message}</div>
              )}
            </div>

            <FormFeedback
              errors={status?.error}
              isVisible={status?.error || formStatus?.success || (!objectIsEmpty(errors) && submitCount > 0)}
              alert={!objectIsEmpty(errors) && submitCount > 0}
            >
              Thank you for your message. We will be in touch shortly.
            </FormFeedback>

            <div className="d-flex justify-content-between align-items-center">
              <button
                type="submit"
                className="btn btn-success"
                disabled={isSubmitting || mutationLoading}
              >
                {!mutationLoading && <span>Submit</span>}
                {mutationLoading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
              <button
                type="reset"
                className="btn btn-link text-success text-decoration-none"
              >
                Clear form
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AltSiteContactPage;
