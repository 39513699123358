import React from 'react';
import { Link as DefaultLink } from 'gatsby';
import SEO from '../../components/seo';
import { graphql } from 'gatsby';
import WithLocation from '../../components/WithLocation';
const Link = WithLocation(({ location, search, children, to, ...rest }) => {
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
        setHasMounted(true);
    }, []);
    if (!hasMounted) {
        return null;
    }
    const hrefWithParameters = 'https://www.beachhousesinparadise.com' + (location.location.search ? `${to}${to.indexOf('?') > -1 ? '&' : '?'}${location.location.search.slice(1)}` : to);
    return <a {...rest} href={hrefWithParameters}>{children}</a>;
});

const IndexPage = ({ data }) => {
    const { sitePage } = data.bhip;
    return (
        <>
            <header className={`bg-white text-center`}>
                <img
                    style={{ 'width': '200px' }}
                    src="/logo-simple.png"

                />
            </header>
            <main>
                <div className="container">
                    <SEO
                        title={sitePage.seoTitle}
                        description={sitePage.seoDescription}
                        headTags={sitePage.headTags}
                    >
                    </SEO>
                    <>
                        <p>BHIP Properties is an investor group that owns or controls a real-estate portfolio of more than two dozen luxury oceanfront and ocean-to-river residential real-estate properties in Southeast Florida.</p>
                        <p>Specializing in the Florida "Treasure Coast" region, most of the group's residences are located in Stuart FL. Stuart's quiet neighborhood of oceanfront residences is located in Martin County, just a few miles north of Martin County's Jupiter Island / Hobe Sound area, one of the wealthiest coastal neighborhoods in America. (Stuart is the next oceanfront neighborhood north of Jupiter Island, just a few miles north of the ultra-affluent enclave)</p>
                        <p>Stuart was recently recognized as "America's Best Coastal Small Town" by USA Today (2024 National Award Winner), and has received numerous similar accolades over the years, including the "Happiest Seaside Town in America" designation from Coastal Living Magazine and "#1 Most Beautiful Small Town in Florida" award by House Beautiful Magazine.</p>
                        <p>For inquiries about BHIP Properties, please contact:</p>
                        <p>Larry Pettit<br /> <a href="mailto:lpettit@superhomelife.com">lpettit@superhomelife.com</a><br /> (561) 262-4949</p>
                    </>
                    <div className={` text-center`}>
                        <img

                            src="/inquiries.png"

                        />
                    </div>
                </div>
            </main>
        </>
    );
};

export const query = graphql`
  query InquiryPage($slug: String) {
    bhip {
      sitePage(where: {slug: $slug }) {
        ...SitePageData
      }
    }
  }
`;
export default IndexPage;
