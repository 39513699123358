import React, { useEffect } from 'react';
// import SEO from '../../components/seo';
// import Layout from '../../layouts/DefaultLayout';

const Page = ({ pageContext, ...props }) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "/js/alt-site-contact.js";
    script.async = true;

    document.body.appendChild(script);


  }, [])
  return (
    <div>
      {/* <SEO title="Page Not found" description="Page Not found" /> */}
      <div className="container mt-3">Secure Information Collection
        <div data-contact-form />

        {/* <iframe style={{
          width: '100%',
          minHeight: '850px'
        }} src="http://localhost:8000/webforms/alt-site-contact?domain=4580southoceandrive.com" id="myIframe" title="Web Form"></iframe> */}

      </div>


    </div>
  );
};

export default Page;
