import React from 'react';
import ReactDatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

const DatePicker = props => {
  return <ReactDatePicker {...props} />;
};

export default DatePicker;
