import React from 'react';

const BeachHouseCompoundsText = ({ promo }) => {
  return (
    <div className="mb-2">
      <h3 className="mb-1">
        <b>Beach-House Compounds</b>
      </h3>
      <p className={`mb-0`}>
        Book multiple beach houses in a single reservation and Save. {!promo && <span>Learn more{' '}
          <a className="pl-0" href="/discount-programs">
            about discounts.
          </a></span>}
      </p>
    </div>
  );
};

export default BeachHouseCompoundsText;
