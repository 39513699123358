import React, { useEffect, useState } from 'react';
import PropertyDetailsList from '../../../../components/PropertyDetailsList';
import Modal from '../../../../components/Modal';
import WithLocation from '../../../../components/WithLocation';
import Cookies from 'js-cookie';
import styles from './styles.module.scss';

const amenitySVGMap = {
  oceanfront: (
    <img alt="Oceanfront" width="28" height="20" src="/oceanfront.svg" />
  ),
  riverfrontRecreationalArea: (
    <img
      alt="Recreation Area"
      width="20"
      height="20"
      src="/recreationarea.svg"
    />
  ),
  riverfront: (
    <img alt="Riverfront Area" width="26" height="20" src="/riverfront.svg" />
  ),
  pool: <img alt="Pool" width="25" height="20" src="/pool.svg" />,
  hottub: <img alt="Hot Tub" width="26" height="20" src="/hottub.svg" />,
  elevator: <img alt="Elevator" width="20" height="20" src="/elevator.svg" />,
  swimSpa: <img alt="Swim Spa" width="28" height="20" src="/swimspa.svg" />,
  dock: <img alt="Dock" width="26" height="20" src="/dock.svg" />,
  resortAccess: (
    <img alt="Resort Access" width="20" height="20" src="/resortaccess.svg" />
  ),
  petFriendly: (
    <img alt="Pet Friendly" width="22" height="20" src="/petfriendly.svg" />
  ),
  kidFriendly: (
    <img alt="Kid Friendly" width="22" height="20" src="/kidfriendly.svg" />
  ),
  puttingGreen: (
    <img alt="Putting Green" width="22" height="20" src="/puttinggreen.svg" />
  ),
};

const PropertyDetails = ({ property, hasBorders, location, search }) => {
  console.log('location', location)
  console.log('search', search)
  const params = new URLSearchParams(location.search);
  useEffect(() => {
    $('[data-toggle="popover"]').popover({
      container: 'body',
    });
    return () => {
      $('[data-toggle="popover"]').popover('dispose');
    };
  });
  const getListItemStyles = (amenity, popoverAttributes) => {
    // causes a wrap of text to prevent blowout on mobile for items without a bottom border for the tooltip (415px resolution and below)
    if (amenity?.name?.length > 20 && popoverAttributes === null) {
      return {};
    }
    return {
      whiteSpace: 'nowrap',
    };
  };

  const getAmenityLabel = amenity => {
    if (amenity?.key === 'riverfrontRecreationalArea') {
      const splitLabelBySpaces = amenity?.name.split(' ');
      return splitLabelBySpaces.map((word, index) => {
        if (index === 0) {
          return <span className={styles.amenitySingleWord}>{word} </span>;
        }
        return <span>{word} </span>;
      });
    }
    return amenity?.name;
  };

  const orderedProperties = property.amenities;
  const AmenityIconGroup = ({ onClick = new Function(), amenity, index }) => {
    const popoverAttributes =
      amenity.key == 'resortAccess'
        ? {
          onClick: () => {
            console.log('amenity', amenity);
            onClick(amenity);
          },
        }
        : amenity.description
          ? {
            'data-trigger': 'hover',
            'data-container': 'body',
            'data-toggle': 'popover',
            'data-placement': 'top',
            'data-template':
              '<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>',
            'data-content': amenity.description,
          }
          : null;

    return (
      <div
        key={`${amenity.key}_${amenity?.index}`}
        className={`d-flex mb-2 ${popoverAttributes && 'tooltip-link'}`}
        {...popoverAttributes}
      >
        <span style={{ width: '40px', textAlign: 'center' }}>
          {amenitySVGMap[amenity.key]}
        </span>
        <span
          className="list-item"
          style={getListItemStyles(amenity, popoverAttributes)}
        >
          {getAmenityLabel(amenity)}
        </span>
      </div>
    );
  };

  const [amenityTarget, setAmenityTarget] = useState('');

  const nodisc = params.get('nodisc') == '1' ? 1 : Cookies.get('nodisc');
  console.log('nodisc2', nodisc)
  return (
    <>
      <div className={`${hasBorders ? 'border-bottom mt-2 pb-2' : ''}`}>
        <h2>Property Details</h2>
        <PropertyDetailsList property={property} showLegalText={nodisc == '1' ? false : true} />
      </div>
      <div className={`${hasBorders ? 'border-bottom mt-2 pb-2' : ''}`}>
        <h2 className="mb-1plus">Amenities</h2>
        <div className="d-flex mt-2 justify-content-between">
          <Modal
            disableFocusTrap={true}
            title={`Resort Access Info`}
            isOpen={amenityTarget == 'resortAccess'}
            expanded={true}
            onClose={() => setAmenityTarget(false)}
            id="resort-modal"
          >
            <p className="mb-2">
              Included free with your rental of 7+ nights: Free Guest passes to
              the Ocean Club amenities of the nearby Marriott Hutchinson Island
              Resort & Marina (3 resort swimming pools, tennis, fitness, & golf)
            </p>
            <div>
              <img
                src="../marriot-collage_900x.jpg"
                width="9"
                height="6"
                style={{ width: '100%' }}
              />
            </div>
            <div className="text-center py-3 mt-1">
              <button
                className="btn btn-secondary text-primary w-50 font-weight-bold rounded"
                onClick={() => {
                  setAmenityTarget(false);
                }}
              >
                Close
              </button>
            </div>
          </Modal>
          <div
            className="pr-1"
            style={{ marginLeft: '-6px' /*, minWidth: '210px' */ }}
          >
            {orderedProperties &&
              orderedProperties.map((amenity, index) => {
                if (index % 2 == 0) {
                  return (
                    <AmenityIconGroup
                      index={index}
                      onClick={amenity => {
                        setAmenityTarget(amenity.key);
                      }}
                      amenity={amenity}
                    />
                  );
                }
                return null;
              })}
          </div>
          <div>
            {orderedProperties &&
              orderedProperties.map((amenity, index) => {
                if (index % 2 == 1) {
                  return (
                    <AmenityIconGroup
                      onClick={amenity => {
                        setAmenityTarget(amenity.key);
                      }}
                      index={index}
                      amenity={amenity}
                    />
                  );
                }
                return null;
              })}
          </div>
        </div>

        <div className="ml-0 row d-none">
          {orderedProperties &&
            orderedProperties.map((amenity, index) => {
              const popoverAttributes = amenity.description
                ? {
                  'data-trigger': 'hover',
                  'data-container': 'body',
                  'data-toggle': 'popover',
                  'data-placement': 'top',
                  'data-template':
                    '<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>',
                  'data-content': amenity.description,
                }
                : null;

              return (
                <div
                  key={amenity.key}
                  className={`${(index + 1) % 2 === 0 ? 'col-8 pl-2' : 'col-4'
                    } mt-1 mb-2 p-0 ${popoverAttributes && 'tooltip-link'}`}
                  {...popoverAttributes}
                >
                  <span className="mr-1plus align-text-bottom">
                    {amenitySVGMap[amenity.key]}
                  </span>
                  <span className="list-item">{amenity.name}</span>
                </div>
              );
            })}
        </div>



      </div>
      <div className={`${hasBorders ? 'border-bottom mt-2 pb-2' : ''}`}>
        <h2 className="mb-1plus">Cancellation Policy</h2>
        <div className="mt-2 media">
          <img src="/img/icon-cancellation-policy.svg" width="17" className="mr-1plus mt-1" />
          <div className="media-body">Cancel any time at least 30 days prior to check-in, for a 100% refund.</div>
        </div>
      </div>
    </>
  );
};

export default WithLocation(PropertyDetails);
